class DynamicYear {
	constructor() {
		throw 'This class is designed to be used statically. Do not create an instance of it.';
	}

	static init() {
		document.getElementById('dynamic-year').textContent = new Date().getFullYear().toString();
	}
}

export default DynamicYear;

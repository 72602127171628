import Swiper, {Autoplay, Navigation} from 'swiper';

class Carousels {
	constructor() {
		throw 'This class is designed to be used statically. Do not create an instance of it.';
	}

	static init() {
		// Basic large carousel
		new Swiper('.carousel-basic-large .swiper', {
			modules: [Navigation],
			loop: true,
			slidesPerView: 'auto',
			spaceBetween: 8,

			breakpoints: {
				0: {
					centeredSlides: true,
				},
				768: {
					centeredSlides: false,
				}
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		// Basic small carousel
		new Swiper('.carousel-basic-small .swiper', {
			modules: [Navigation],
			loop: true,
			slidesPerView: 1,

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		// Photo grid carousel
		new Swiper('.carousel-photo-grid .swiper', {
			modules: [Navigation],
			loop: true,
			spaceBetween: 24,

			breakpoints: {
				0: {
					slidesPerView: 1.5,
				},
				768: {
					slidesPerView: 'auto',
				}
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});

		// Card carousel
		new Swiper('.carousel-cards .swiper', {
			loop: false,
			slidesPerView: 'auto',
			spaceBetween: 24,

			breakpoints: {
				0: {
					slidesPerView: 1.5,
				},
				768: {
					slidesPerView: 3,
				}
			},
		});

		// Quote carousel
		new Swiper('.carousel-quotes .swiper', {
			modules: [Navigation, Autoplay],
			loop: true,
			slidesPerView: 1,
			autoplay: {
				// To accommodate shorter and longer quotes, this is overridden on individual slides with the data-swiper-autoplay attribute
				delay: 10000,
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}
}

export default Carousels;

import { animate, inView, stagger, spring } from 'motion';

class Animations {
	constructor() {
		throw 'This class is designed to be used statically. Do not create an instance of it.';
	}

	static inViewStaggerChildren(selector, childSelector, settings) {
	  selector = document.querySelectorAll(selector);
	  inView(selector, (info) => {
	    // remove opacity 0 from parent
	    animate(info.target, { opacity: [0, "1"] }, { duration: 0.5 });
	    //document.querySelector(selector).classList.remove("opacity-0");
	    animate(
	      childSelector,
	      settings,
	      {
	        delay: stagger(0.15),
	        duration: 0.5,
	        easing: spring({ damping: 20 }),
	      }
	    );
	  });
	}

	static inViewItem(selector, settings, options) {
	  selector = document.querySelectorAll(selector);
	  selector.forEach((el) => {
	    // get data-in-view-options value
	    let selectorOptions = el.getAttribute("data-in-view-options");
	    if (selectorOptions) {
	      selectorOptions = JSON.parse(selectorOptions);
	    } else {
	      selectorOptions = "{}";
	    }
	    // merge options
	    options = { ...options, ...selectorOptions };

	    inView(el, (info) => {
	      animate(
	        info.target,
	        settings,
	        {
	          duration: options.duration ? options.duration : 0.3,
	          easing: options.easing ? options.easing :  'ease-out',
	          delay: options.delay ? options.delay : 0
	        },
	      );
	    });
	  });
	}

	static init() {
	  this.inViewStaggerChildren(
	    '[data-in-view="stagger-parent"]',
	    '[data-in-view="stagger-child"]',
	    {
	      x: ["200px", "0px"],
	      y: ["-100px", "0px"],
	      scale: [0.8, "1"],
	      opacity: [0, "1"],
	      rotate: ["-4deg", "0deg"],
	    });
	  this.inViewStaggerChildren(
	    '[data-in-view="stagger-parent-appear"]',
	    '[data-in-view="stagger-child-appear"]',
	    {
	      x: ["50px", "0px"],
	      opacity: [0, "1"],
	    });
	  this.inViewItem(
	    "[data-in-view='polaroid']",
	    {
	      opacity: [0, "1"],
	      rotate: ["-4deg", "0deg"],
	      scale: [1.2, "1"],
	    },
	    {
	      delay: 0.5,
	      duration: 0.75,
	      easing: spring()
	    }
	  );
	  this.inViewItem(
	    "[data-in-view='hero']",
	    { opacity: [0, "1"], },
	    { delay: 0, duration: 2,}
	  );
	  this.inViewItem(
	    "[data-in-view='fade-in']",
	    { opacity: [0, "1"], y: ["50px", "0px"], },
	    { delay: 0, duration: 0.3,}
	  );
	  this.inViewItem(
	    "[data-in-view='add-section']",
	    { opacity: [0, "1"], x: ["50px", "0px"], },
	    { delay: 0, duration: 0.6, easing: spring({ velocity: 100, damping: 30 }) }
	  );
	  this.inViewItem(
	    "[data-in-view='add-carousel']",
	    { opacity: [0, "1"], x: ["100px", "0px"], },
	    { delay: 0.3, duration: 1,}
	  );
	  document.querySelectorAll('.btn-animated-ring').forEach((element) => {
		element.addEventListener('mouseenter', function(){
			this.classList.add('exit-animation');
		});
	  });
	}
}

export default Animations;

class MobileNav {
	constructor() {
		throw 'This class is designed to be used statically. Do not create an instance of it.';
	}

	static init() {
		let mobileMenuButton = document.querySelector('.mobile-menu-button');
		let primaryNav = document.querySelector('#primary-nav');
		let firstNavLink = primaryNav.querySelector('li:first-child a');

		// Toggle mobile nav when clicking the hamburger button
		mobileMenuButton.addEventListener('click', (event) => {
			event.preventDefault();

			if (primaryNav.classList.contains('visible')) {
				this.closeMobileMenu(primaryNav, mobileMenuButton);
			} else {
				primaryNav.classList.add('visible');
				mobileMenuButton.classList.add('nav-visible');

				primaryNav.setAttribute('aria-hidden', 'false');
				mobileMenuButton.setAttribute('aria-expanded', 'true');
				mobileMenuButton.setAttribute('aria-label', 'Hide navigation');
				firstNavLink.focus();
			}
		});

		// Close mobile nav when pressing the ESC key
		primaryNav.addEventListener('keyup', (event) => {
			if (primaryNav.classList.contains('visible') && event.keyCode === 27) {
				this.closeMobileMenu(primaryNav, mobileMenuButton);
			}
		});

		// Close mobile nav when clicking anywhere outside
		document.addEventListener('click', (event) => {
			if (primaryNav.classList.contains('visible') && !primaryNav.contains(event.target) && !event.target.matches('.mobile-menu-button')) {
				this.closeMobileMenu(primaryNav, mobileMenuButton);
			}
		});
	}

	static closeMobileMenu(primaryNav,  mobileMenuButton) {
		primaryNav.classList.remove('visible');
		mobileMenuButton.classList.remove('nav-visible');

		primaryNav.setAttribute('aria-hidden', 'true');
		mobileMenuButton.setAttribute('aria-expanded', 'false');
		mobileMenuButton.setAttribute('aria-label', 'Show navigation');
		mobileMenuButton.focus();
	}
}

export default MobileNav;

class DropdownNav {
	constructor() {
		throw 'This class is designed to be used statically. Do not create an instance of it.';
	}

	static init() {
		let triggerSelector = '.dropdown-trigger';
		let dropdownMenuContainers = document.querySelectorAll('.dropdown-menu-container');
		let primaryNav = document.querySelector('#primary-nav');

		dropdownMenuContainers.forEach((container) => {
			let trigger = container.querySelector(triggerSelector);
			let menu = container.querySelector('.dropdown-menu');

			// Toggle dropdown when clicking the trigger button
			trigger.addEventListener('click', (event) => {
				event.preventDefault();
				if (menu.classList.contains('visible')) {
					this.closeDropDown(container, menu, trigger);
				} else {
					let firstLink = menu.querySelector('li:first-child a');
					menu.classList.add('visible');
					container.classList.add('dropdown-visible');

					menu.setAttribute('aria-hidden', 'false');
					container.setAttribute('aria-expanded', 'true');
					container.setAttribute('aria-label', 'Hide dropdown menu');
					menu.querySelectorAll('a').forEach(anchor => anchor.removeAttribute('tabindex'));
					firstLink.focus();
				}
			});

			// Close dropdown when pressing the ESC key
			container.addEventListener('keyup', (event) => {
				if (menu.classList.contains('visible') && event.keyCode === 27) {
					this.closeDropDown(container, menu, trigger);

					if (primaryNav.classList.contains('visible')) {
						// Don't let the keyup event propagate up to the parent element, so the entire mobile nav doesn't close
						event.stopPropagation();
					}
				}
			});

			// Close dropdown when clicking anywhere outside
			document.addEventListener('click', (event) => {
				if (menu.classList.contains('visible') && !menu.contains(event.target) && !event.target.matches(triggerSelector)) {
					// If the mobile nav is visible, allow clicks outside the dropdown menu if they're still in the mobile nav
					if (primaryNav.classList.contains('visible') && primaryNav.contains(event.target)) {
						return true;
					}

					this.closeDropDown(container, menu, trigger);
				}
			});
		});

	}

	static closeDropDown(container, menu, trigger) {
		menu.classList.remove('visible');
		container.classList.remove('dropdown-visible');

		menu.setAttribute('aria-hidden', 'true');
		container.setAttribute('aria-expanded', 'false');
		container.setAttribute('aria-label', 'Show dropdown menu');
		menu.querySelectorAll('a').forEach(anchor => anchor.setAttribute('tabindex', '-1'));
		trigger.focus();
	}
}

export default DropdownNav;

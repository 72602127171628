import Animations from './animations.mjs';
import MobileNav from './components/mobile-nav.mjs';
import DropdownNav from './components/dropdown-nav.mjs';
import Carousels from './components/carousels.mjs';
import Tabs from "./components/tabs.mjs";
import DynamicYear from './components/dynamic-year.mjs';

Animations.init();
MobileNav.init();
DropdownNav.init();
Carousels.init();
DynamicYear.init();

// Initialize tab component
window.addEventListener('load', function () {
	let tablists = document.querySelectorAll('[role=tablist].automatic');
	for (let i = 0; i < tablists.length; i++) {
		new Tabs(tablists[i]);
	}
});
